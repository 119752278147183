import * as React from 'react'
import { useEffectOnce } from 'react-use'

import styled from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { OfferModel, PricingTier } from '@igs-web/common-models/models/offer-model'
import { isFeatureOn } from '@igs-web/common-utilities/services/feature-flag-service'

import { FeatureFlag } from 'root/constants'

import { ExternalLink } from '../../_atoms/link/link'
import { ToggleSwitch } from '../../_atoms/toggle-switch'
import { NoWrapText } from '../../_atoms/typography'
import { Breakpoint } from '../../styles/breakpoints'
import { FontWeight } from '../../styles/font-weight'
import { Spacing } from '../../styles/spacing'
import { fontSizeSmall } from '../../styles/theme'

import { RateDetailPanel } from './rate-detail-panel'

const ListView = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Spacing.Medium};
`

const SliderContainer = styled.div`
    text-align: center;
    font-size: ${fontSizeSmall};
    font-weight: ${FontWeight.Bold};
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: ${Spacing.Medium};
    justify-items: center;

    @media (min-width: ${Breakpoint.Tablet}) {
        text-align: left;
        justify-content: space-between;
        grid-template-columns: 1fr max-content;
        justify-items: start;
    }
`

export const RatesListViewDisplay = ({
    offers,
    setSelectedOffer,
    btnMessage,
    className,
    showMapLink = false,
    selectedOffers,
    hideButton = false,
    priceTier,
    dataTestIdPrefix,
    showCarbonNeutral = false,
}: RatesListViewProps) => {
    const [carbonNeutralToggle, setCarbonNeutralToggle] = React.useState(true)

    const hasCarbonNeutralOffers = offers.some(o => o.primaryProduct.isGreen)

    // sort by price, then term (varible move to bottom), then move hero to the top
    const sortedOffers = offers
        .slice()
        .filter(o => (carbonNeutralToggle ? true : !o.primaryProduct.isGreen))
        .sort((a, b) => a.primaryProduct.price - b.primaryProduct.price)
        .sort((a, b) =>
            a.primaryProduct.termMonths && b.primaryProduct.termMonths
                ? b.primaryProduct.termMonths - a.primaryProduct.termMonths
                : a.primaryProduct.termMonths
                  ? -1
                  : 1,
        )
        .sort((a, b) => (a.primaryProduct.isHeroProduct === b.primaryProduct.isHeroProduct ? 0 : a.primaryProduct.isHeroProduct ? -1 : 1))
    const company = useCompany()

    const handleToggleChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setCarbonNeutralToggle(checked)
    }

    const showCarbonNeutralOptions = showCarbonNeutral && hasCarbonNeutralOffers

    return (
        <ListView className={className} data-testid={`${dataTestIdPrefix}-list-view`}>
            {showCarbonNeutralOptions && (
                <SliderContainer>
                    <div>
                        {company.displayName} now offers carbon-neutral <NoWrapText>rate plans</NoWrapText>.
                        <br />
                        <ExternalLink href={`${company.externalWebsite}/carbon-neutral`}>Learn More</ExternalLink> about how you can reduce your{' '}
                        <NoWrapText>carbon footprint.</NoWrapText>
                    </div>
                    <ToggleSwitch
                        label={`${carbonNeutralToggle ? '' : 'Not '}Showing Carbon-Neutral Plans`}
                        checked={carbonNeutralToggle}
                        onChange={handleToggleChanged}
                    />
                </SliderContainer>
            )}

            {sortedOffers.map((o, index) => (
                <RateDetailPanel
                    setSelectedOffer={setSelectedOffer}
                    key={o.offerId}
                    offer={o}
                    btnMessage={btnMessage}
                    showMapLink={showMapLink}
                    selectedOffers={selectedOffers}
                    hideButton={hideButton}
                    priceTier={priceTier}
                    dataTestIdPrefix={`${dataTestIdPrefix}-${index}`}
                />
            ))}
        </ListView>
    )
}

export const RatesListView = ({ showCarbonNeutral, ...props }: RatesListViewProps) => {
    const [showCarbonNeutralGasFeatureFlag, setShowCarbonNeutralGasFeatureFlag] = React.useState(false)
    useEffectOnce(() => {
        const getFeatureFlag = async () => {
            const isOn = await isFeatureOn(FeatureFlag.CarbonNeutralGas)
            setShowCarbonNeutralGasFeatureFlag(isOn)
        }
        getFeatureFlag()
    })

    return <RatesListViewDisplay showCarbonNeutral={showCarbonNeutral && showCarbonNeutralGasFeatureFlag} {...props} />
}

interface RatesListViewProps {
    readonly offers: ReadonlyArray<OfferModel>
    readonly selectedOffers?: ReadonlyArray<OfferModel>
    readonly btnMessage?: string
    readonly setSelectedOffer: (offer: OfferModel) => void
    readonly className?: string
    readonly showMapLink?: boolean
    readonly hideButton?: boolean
    readonly priceTier?: PricingTier
    readonly dataTestIdPrefix: string
    readonly showCarbonNeutral?: boolean
}
