import * as React from 'react'
import { useSelector } from 'react-redux'

import styled from 'styled-components'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { OfferSelectors } from '@igs-web/common-components/domain/enrollment/offer-redux'
import { useOffersContextState } from '@igs-web/common-components/domain/offers/context/offers-context'
import { LineOfBusinessCode } from '@igs-web/common-models/models'
import { OfferModel, ProductTypeCode } from '@igs-web/common-models/models/offer-model'
import { Link } from '@igs-web/common-ui-components/_atoms/link/link'
import { Header3 } from '@igs-web/common-ui-components/_atoms/typography'
import { GridSpacer } from '@igs-web/common-ui-components/_molecules/grid-layout'
import { RatesListView } from '@igs-web/common-ui-components/_molecules/rates/rates-list-view'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { fontSizeLarge } from '@igs-web/common-ui-components/styles/theme'
import { formatPhoneNumberForDisplay } from '@igs-web/common-utilities/utilities/phone-utilities'

const RatesContainer = styled.div`
    padding-inline: ${Spacing.ExtraSmall};
`
export const RatesSectionHeading = styled(Header3)`
    font-size: ${fontSizeLarge};
    margin-block: 0;
    padding-bottom: ${Spacing.Large};
`
const DefaultHeroOfferCount = 2

const filterFixedOffers = (offers: ReadonlyArray<OfferModel>, showHeroOnly: boolean) => {
    const fixedOffers = offers.filter(
        offer => offer.primaryProduct.productType === ProductTypeCode.Fixed && offer.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.Gas,
    )
    if (showHeroOnly) {
        return fixedOffers.filter(o => o.primaryProduct.isHeroProduct).length > 0
            ? offers.filter(o => o.primaryProduct.isHeroProduct)
            : offers.slice(0, DefaultHeroOfferCount)
    }
    return fixedOffers
}

export const OfferListView = ({ offers, handleSelectedOffer, showHeroOnly: showHeroOnly = false }: OfferListViewProps) => {
    const { phoneNumber } = useCompany()

    const { selectedOffer, offersAreLoaded: offersLoadedInContext, offersAreLoading: offersAreLoadingInContext } = useOffersContextState()

    const offersLoadedInRedux = useSelector((store: CommonReduxState) => OfferSelectors.selectOffersLoaded(store))
    const offerRequestInRedux = useSelector((store: CommonReduxState) => OfferSelectors.selectOfferRequest(store))
    const selectedOffersInRedux = useSelector((store: CommonReduxState) => OfferSelectors.selectSelectedOffers(store))

    const offersAreLoaded = offersLoadedInContext || offersLoadedInRedux
    const offersAreLoading = offersAreLoadingInContext || !!offerRequestInRedux.isLoading
    const selectedOffers = selectedOffer ? new Array<OfferModel>().fill(selectedOffer) : selectedOffersInRedux

    const fixedOffers = filterFixedOffers(offers, showHeroOnly)
    const variableOffers = offers.filter(
        offer => offer.primaryProduct.productType !== ProductTypeCode.Fixed && offer.primaryProduct.lineOfBusinessCode === LineOfBusinessCode.Gas,
    )

    return (
        <RatesContainer id="rates-container" data-testid="rates-container">
            {selectedOffers.length > 0 && offers.some(o => selectedOffers.some(s => s.offerId === o.offerId)) && (
                <>
                    <RatesSectionHeading>Selected Rate</RatesSectionHeading>
                    <RatesListView
                        offers={selectedOffers}
                        setSelectedOffer={handleSelectedOffer}
                        btnMessage="Sign Up"
                        selectedOffers={selectedOffers}
                        dataTestIdPrefix="selected-offer"
                        showCarbonNeutral={false}
                    />
                    <GridSpacer spacing={Spacing.Medium} />
                </>
            )}
            {fixedOffers.length > 0 && (
                <>
                    {!showHeroOnly && <RatesSectionHeading>Fixed Rates</RatesSectionHeading>}

                    <RatesListView
                        offers={fixedOffers}
                        setSelectedOffer={handleSelectedOffer}
                        btnMessage="Sign Up"
                        dataTestIdPrefix="fixed-offer"
                        showCarbonNeutral={true}
                    />
                    {variableOffers.length > 0 && <GridSpacer spacing={Spacing.Medium} />}
                </>
            )}
            {variableOffers.length > 0 && !showHeroOnly && (
                <>
                    <RatesSectionHeading>Variable Rates</RatesSectionHeading>
                    <RatesListView
                        offers={variableOffers}
                        setSelectedOffer={handleSelectedOffer}
                        btnMessage="Sign Up"
                        dataTestIdPrefix="variable-offer"
                        showCarbonNeutral={true}
                    />
                </>
            )}
            {offersAreLoaded && !offersAreLoading && !fixedOffers.length && !variableOffers.length && (
                <p>
                    Certain rates are not available in all locations. For further information regarding availability, please call{' '}
                    <Link href={`tel:${phoneNumber.customerSupport}`}>{formatPhoneNumberForDisplay(phoneNumber.customerSupport)}</Link>.
                </p>
            )}
        </RatesContainer>
    )
}
interface OfferListViewProps {
    readonly offers: ReadonlyArray<OfferModel>
    readonly handleSelectedOffer: (offer: OfferModel) => void
    readonly showHeroOnly?: boolean
    readonly showCarbonNeutral?: boolean
}
