import * as React from 'react'

import { css, styled } from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { OfferModel, PricingTier, ProductTypeCode } from '@igs-web/common-models/models'
import { PriceInformationType } from '@igs-web/common-models/models/price-information-model'
import { Button } from '@igs-web/common-ui-components/_atoms/buttons/button'
import { ExternalLink } from '@igs-web/common-ui-components/_atoms/link/link'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { FontWeight } from '@igs-web/common-ui-components/styles/font-weight'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import {
    borderBoxColor,
    borderBoxRadius,
    btnBorder,
    darkDark,
    fontSizeExtraLarge,
    fontSizeMedium,
    fontSizeVerySmall,
    headerColor,
} from '@igs-web/common-ui-components/styles/theme'
import { MoneyFormat, formatAsMoney } from '@igs-web/common-utilities/utilities/currency-utilities'
import { getPriceWithoutUnitOfMeasureForProduct, getProductTitle } from '@igs-web/common-utilities/utilities/offer-utilities'

import { AccordionAnimation } from 'root/views/product-selection-new/accordion-animation'

interface RatePanelContainerProps {
    readonly isOfferSelected?: boolean
}

const RatePanelContainer = styled.div<RatePanelContainerProps>`
    display: grid;
    grid-template-areas:
        'title price'
        'terms terms'
        'toggle action';
    grid-template-columns: 1fr max-content;
    grid-template-rows: 1fr min-content min-content;
    width: 100%;

    padding: ${Spacing.Medium};
    ${p => (p.isOfferSelected ? selectedOfferCss : unSelectedOfferCss)}
    border-radius: ${borderBoxRadius};
`

const unSelectedOfferCss = css`
    border: 1px solid ${borderBoxColor};
`
const selectedOfferCss = css`
    border: 2px solid ${btnBorder};
`
const TitleContainer = styled.div`
    grid-area: title;
`
const Title = styled.div`
    font-size: ${fontSizeExtraLarge};
    font-weight: ${FontWeight.Bold};
    line-height: 1.2;
    @media (max-width: ${Breakpoint.Tablet}) {
        font-size: ${fontSizeMedium};
    }
`
const PriceDisplay = styled.div`
    grid-area: price;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-self: start end;
    padding-top: ${Spacing.ExtraSmall};
`
const Price = styled.div`
    font-size: ${fontSizeExtraLarge};
    font-weight: ${FontWeight.Bold};
`
const UnitOfMeasure = styled.div`
    font-size: ${fontSizeVerySmall};
    text-transform: uppercase;
`
const Actions = styled.div`
    grid-area: action;
    place-self: start end;
    display: flex;
    flex-direction: column;
    gap: ${Spacing.Medium};
`

const termsBoxCss = css`
    padding: ${Spacing.Medium} ${Spacing.ExtraLarge};
    @media (max-width: ${Breakpoint.Tablet}) {
        padding: ${Spacing.Medium} ${Spacing.Large};
    }
`
const Terms = styled.ul<TermsProps>`
    grid-area: terms;

    border-bottom: 1px solid ${borderBoxColor};
    ${({ showTerms }) => (showTerms ? termsBoxCss : '')}
`
interface TermsProps {
    readonly showTerms: boolean
}

const ToggleTerms = styled.div`
    grid-area: toggle;
    color: ${headerColor};
    font-weight: ${FontWeight.Bold};
    cursor: pointer;
    &:hover {
        color: ${darkDark};
    }
`
const SubTitleMessage = styled.div`
    font-size: ${fontSizeVerySmall};
    padding-top: ${Spacing.ExtraSmall};
`
const StyledButton = styled(Button)`
    padding-block: ${Spacing.ExtraSmall};
    padding-inline: ${Spacing.Medium};
    letter-spacing: ${Spacing.None};
    height: min-content;
`

//ultimately, this needs redone in react, but currently integrates with scana sitefinity multisite javascript -- BF
const SitefinityMapLink = () => (
    <SubTitleMessage>
        Not available in all areas. <a data-open="map">Get details...</a>.
    </SubTitleMessage>
)

export const RateDetailPanel = ({
    offer,
    setSelectedOffer,
    btnMessage = 'Select',
    showMapLink = false,
    selectedOffers,
    hideButton = false,
    priceTier,
    dataTestIdPrefix,
}: RatePanelProps) => {
    const [showTerms, setShowTerms] = React.useState(false)
    const { primaryProduct } = offer
    const company = useCompany()
    const routes = company.routes
    const customTerms = primaryProduct.customTerms || []
    const pipelineCapacityCharge = primaryProduct.supplementalPriceInformation?.find(p => p.type === PriceInformationType.PipelineCapacity)

    const toggleTerms = () => {
        setShowTerms(!showTerms)
    }

    const isOfferSelected = selectedOffers?.some(o => o.offerId === offer.offerId)
    return (
        <RatePanelContainer isOfferSelected={isOfferSelected}>
            <TitleContainer>
                <Title data-testid={`${dataTestIdPrefix}-plan-title`}>{getProductTitle(primaryProduct, company)}</Title>
                {pipelineCapacityCharge && (
                    <SubTitleMessage>
                        The Interstate Pipeline Charge is {formatAsMoney(pipelineCapacityCharge.amount, MoneyFormat.Dollars)} X{' '}
                        {pipelineCapacityCharge.unitOfMeasure}
                    </SubTitleMessage>
                )}
                {primaryProduct.productType === ProductTypeCode.Fixed && showMapLink && <SitefinityMapLink />}
            </TitleContainer>
            <PriceDisplay>
                <Price data-testid={`${dataTestIdPrefix}-plan-price`}>{getPriceWithoutUnitOfMeasureForProduct(primaryProduct, true)}</Price>
                <UnitOfMeasure>PER {primaryProduct.unitOfMeasure}</UnitOfMeasure>
            </PriceDisplay>
            <Terms showTerms={showTerms}>
                <AccordionAnimation show={showTerms} animationKey={`terms-${offer.offerId}`}>
                    {customTerms.map((term, i) => (
                        <li key={i}>{term}</li>
                    ))}
                    <li>
                        <ExternalLink
                            href={routes.product.termsAndConditions(
                                primaryProduct.productCode,
                                primaryProduct.accountType,
                                undefined,
                                undefined,
                                undefined,
                                priceTier,
                            )}
                        >
                            Terms and conditions
                        </ExternalLink>{' '}
                        apply.
                    </li>
                </AccordionAnimation>
            </Terms>
            {customTerms.length > 0 && <ToggleTerms onClick={toggleTerms}>{showTerms ? '- Hide Terms' : '+ Show Terms'}</ToggleTerms>}
            <Actions>
                {!hideButton && (
                    <StyledButton onClick={() => setSelectedOffer(offer)} data-testid={`${dataTestIdPrefix}-plan-sign-up`}>
                        {btnMessage}
                    </StyledButton>
                )}
            </Actions>
        </RatePanelContainer>
    )
}

interface RatePanelProps {
    readonly offer: OfferModel
    readonly btnMessage?: string
    readonly setSelectedOffer: (offer: OfferModel) => void
    readonly showMapLink?: boolean
    readonly selectedOffers?: ReadonlyArray<OfferModel>
    readonly hideButton?: boolean
    readonly priceTier?: PricingTier
    readonly dataTestIdPrefix: string
}
